import { fetchWithTimeout } from './fetch';
import { logError } from './logging';

export const fetchDeeplinkData = async (callbackURL, timeout) => {
  const response = await fetchWithTimeout(
    callbackURL,
    {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'application/json',
      },
    },
    timeout,
  );

  const { headers, status, statusText } = response;

  try {
    // TODO: Review status conditions as the below code is not being called in production
    // If PX return 403 - forbidden, then we need to present a Captcha page to the user
    const payload = await response.json();
    if (status === 403 && headers.get('content-type') === 'application/json') {
      logError(
        'redirects_service_403',
        'Have a 403 response from redirects service',
        0,
        'info',
      );
      // redirect_to will point to the captcha page.
      // Using window.location for cross browser safety https://www.w3.org/TR/html/browsers.html#dom-location
      if (payload.reason === 'blocked' && payload.redirect_to) {
        window.location = payload.redirect_to;
        logError('captcha_page', 'Redirected to captcha page', 0, 'info');
      }
    }
    return { status, payload, statusText };
  } catch (error) {
    logError('fetch_with_timeout_json_failure', error.message, 0, 'info');
    return { status, error: 'json_parse_error' };
  }
};
